const menu_data = [
  {
    id: 1,
    title: "Courses",
    link: "/course-list",
    has_dropdown: false,
    sub_menus: [
     
    ],
  },
  {
    id: 2,
    title: "Levels",
    link: "#",
    has_dropdown: false,
    sub_menus: [
      
    ],
  },
  
];

export default menu_data;
